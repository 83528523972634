<template>
  <div class="home">
    <CarrotHeader></CarrotHeader>

    <!-- visual top start -->
    <div class="main-visual-wrap">
      <!-- <div class="logo"><img src="@/assets/img/logo_white.png" /></div> -->
      <!-- <div class="logo"><img src="@/assets/img/logo_black.png" /></div> -->
      <ThanksDollarViewPopup
        v-model:showList.sync="pop.isShowThxDollarList"
        v-if="pop.isShowThxDollarView"
        @close="pop.hideThanksDollar"
      ></ThanksDollarViewPopup>
      <AssetsPopup
        v-model:showList.sync="pop.isShowThxDollarList"
        v-if="pop.isShowThxDollarView"
        @close="pop.hideThanksDollar"
      ></AssetsPopup>
      <ThanksDollarListPopup
        v-if="pop.isShowThxDollarList"
        @close="pop.hideThxDollarList"
      ></ThanksDollarListPopup>
      <MCRMCGAddInfoPopup
        v-if="pop.isShowFeedback"
        @close="pop.hideFeedbackPop"
      ></MCRMCGAddInfoPopup>
      <div class="main-visual-wrap-all">
        <div class="main-visual">
          <div class="assets-popup" v-if="pop.isAssetsPopup == 'Y'">
            <div class="inner">
              <div class="top">
                <img src="@/assets/img/assetTitle.png" class="popup-logo" />
                <p class="date">
                  기간 {{ pop.assetsPopupInfo.sdate }}~{{
                    pop.assetsPopupInfo.edate
                  }}
                </p>
                <div>
                  <div v-html="pop.assetsPopupInfo.contents" class="fiy"></div>
                  <div
                    v-html="pop.assetsPopupInfo.contents_info"
                    class="step"
                  ></div>
                </div>
                <p class="go" @click="pop.openUrl(pop.assetsPopupInfo.link)">
                  바로가기
                </p>
              </div>
              <div class="bottom">
                <p @click="pop.hideAssetPopupOneday">오늘 하루 보지 않기</p>
                <p class="close" @click="pop.hideAssetPopup">닫기</p>
              </div>
            </div>
          </div>

          <div class="user-box">
            <ul class="user_left">
              <li>
                <ul v-if="store.state.gnb != null">
                  <li @click="mnu.moveDoc('T4', '진행중')" class="pointer">
                    기안한 문서<a class="bold txt-right float-right pointer"
                      ><span
                        v-if="store.state.gnb.request.is_new == 'Y'"
                        class="normal new user-new"
                        >N</span
                      >{{ store.state.gnb.request.count }}</a
                    >
                  </li>
                  <li @click="mnu.moveDoc('T1', '진행중')" class="pointer">
                    결재할 문서<a class="bold txt-right float-right pointer"
                      ><span
                        v-if="store.state.gnb.sign.is_new == 'Y'"
                        class="normal new user-new"
                        >N</span
                      >{{ store.state.gnb.sign.count }}</a
                    >
                  </li>
                  <li @click="mnu.moveDoc('T5', '')" class="pointer">
                    참조된 문서<a class="bold txt-right float-right pointer"
                      ><span
                        v-if="store.state.gnb.referenced.is_new == 'Y'"
                        class="normal new user-new"
                        >N</span
                      >{{ store.state.gnb.referenced.count }}</a
                    >
                  </li>
                  <li @click="mnu.moveDoc('P2', '완료')" class="pointer">
                    완료된 문서<a class="bold txt-right float-right pointer"
                      ><span
                        v-if="store.state.gnb.done.is_new == 'Y'"
                        class="normal new user-new"
                        >N</span
                      >{{ store.state.gnb.done.count }}</a
                    >
                  </li>
                  <li @click="mnu.moveDoc('P3', '반려')" class="pointer">
                    반려된 문서<a class="bold txt-right float-right pointer"
                      ><span
                        v-if="store.state.gnb.returned.is_new == 'Y'"
                        class="normal new user-new"
                        >N</span
                      >{{ store.state.gnb.returned.count }}</a
                    >
                  </li>
                  <li
                    @click="mnu.moveLink('documents-DeptInboxList')"
                    class="pointer"
                  >
                    부서수신함
                    <a class="bold txt-right float-right pointer"
                      ><span
                        v-if="store.state.gnb.received.is_new == 'Y'"
                        class="normal new user-new"
                        >N</span
                      >{{ store.state.gnb.received.count }}</a
                    >
                  </li>
                </ul>
              </li>
            </ul>
            <ul class="user_right">
              <li>
                <ul v-if="store.state.gnb != null">
                  <li
                    @click="mnu.moveLink('myPIMS-myVacationList')"
                    class="pointer"
                  >
                    잔여 연차<a class="bold txt-right float-right pointer"
                      ><span
                        v-if="store.state.gnb.vacation.is_new == 'Y'"
                        class="normal new user-new"
                        >N</span
                      >{{ store.state.gnb.vacation.count_txt }}</a
                    >
                  </li>
                  <li
                    @click="mnu.moveLink('myPIMS-myEduManagemetList')"
                    class="pointer"
                  >
                    교육 학점
                    <a class="bold txt-right float-right pointer"
                      ><span
                        v-if="store.state.gnb.education.is_new == 'Y'"
                        class="normal new user-new"
                        >N</span
                      >{{ store.state.gnb.education.grade_txt }}</a
                    >
                  </li>
                  <li @click="pop.showThanksDollar()" class="pointer">
                    감사 달러<a class="bold txt-right float-right pointer"
                      ><span
                        v-if="store.state.gnb.thanks.is_new == 'Y'"
                        class="normal new user-new"
                        >N</span
                      >{{ store.state.gnb.thanks.dollar_txt }}</a
                    >
                  </li>

                  <li
                    class="pointer"
                    @click="mnu.moveLink('BusinessManagement-ERList')"
                  >
                    <span
                      >go ER
                      <img
                        style="width: 15px; height: 15px"
                        src="@/assets/img/icon-arrow.png"
                    /></span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="quick-menu-wrap">
          <a href="#quick-menu" class="quick-img">
            <img src="@/assets/img/quick.png" />
          </a>
          <div class="quick-menu-list">
            <ul>
              <li></li>
              <li>
                <a href="/documents/SalesManagementPublishList">세금계산서</a>
              </li>
              <li>
                <a href="/ResourceManagement/VehicleReservationLogDay"
                  >차량운행</a
                >
              </li>
              <li>
                <a href="/ResourceManagement/ClassroomReservationDate"
                  >센터예약</a
                >
              </li>
              <li>
                <a href="#quick-menu"
                  >QUICK MENU <i class="fas fa-arrow-down"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- visual top end -->
    <div class="main-con">
      <div class="con-live-wrap">
        <div class="w-100per">
          <div class="con-notice-wrap">
            <div class="con-notice" style="margin: 0px">
              <div class="title">
                <h3 class="bold">공지사항 (Notice)</h3>
                <router-link to="/CARROTZone/NoticeList" class="more"
                  >더보기</router-link
                >
              </div>
              <hr />
              <table>
                <tbody>
                  <tr
                    v-for="(irow, i) in notice.list"
                    :key="i"
                    @click="notice.showDetail(irow.idx)"
                    class="pointer"
                    style="line-height: 2.86"
                  >
                    <td style="padding-left: 13px">
                      · {{ irow.title }}
                      <span
                        v-if="irow.is_new == 'Y'"
                        style="
                          font-size: 13px;
                          width: 15px;
                          height: 14px;
                          display: inline-block;
                          background-color: #fe0000;
                          border-radius: 50px;
                          text-align: center;
                          line-height: 14px;
                          vertical-align: middle;
                          margin-right: 5px;
                          color: #fff;
                          font-size: 11px;
                          margin-top: -3px;
                          margin-left: 5px;
                        "
                      >
                        N
                      </span>
                    </td>
                  </tr>
                  <tr v-if="notice.total == 0" class="txt-center">
                    <td colspan="3">공지사항이 없습니다</td>
                  </tr>
                </tbody>
              </table>
              <hr style="opacity: 0.1; background-color: #000" />
            </div>

            <div
              class="con-comment"
              style="
                margin: 0px;
                margin-top: 50px;
                margin-left: 43px;
                margin-bottom: 60px;
              "
            >
              <div stlye=" display: inline-block;float:left">
                <div class="main-line-comment" style="height: 240px">
                  <ul class="comments-banner">
                    <template v-for="(irow, i) in roll2.list" :key="i">
                      <li
                        :class="{
                          prevroll_new: roll2.prev == irow.idx,
                          currentroll_new: roll2.curr == irow.idx,
                          nextroll_new: roll2.next == irow.idx,
                        }"
                        @click="roll2.moveLink(irow.idx_bbs, irow.bbs_name)"
                      >
                        <table>
                          <tr>
                            <td>
                              <img
                                :src="irow.photouri"
                                style="
                                  width: 56px;
                                  height: 56px;
                                  border-radius: 200px;
                                  position: relative;
                                  top: -20px;
                                "
                              />
                            </td>
                            <td class="comment-box">
                              <table style="width: 100%">
                                <tr>
                                  <td
                                    style="
                                      text-align: left;
                                      width: 50%;
                                      position: relative;
                                      top: -36px;
                                      left: -10px;
                                    "
                                  >
                                    <p
                                      style="
                                        font-size: 12px;
                                        font-weight: bold;
                                        text-align: left;
                                        color: #4f4f4f;
                                      "
                                    >
                                      {{ irow.ename }}
                                      <span v-if="irow.kname != null"
                                        >({{ irow.kname }})</span
                                      >
                                      <span v-if="irow.kname == null">-</span>
                                      <span
                                        v-if="irow.is_today == 'Y'"
                                        style="
                                          width: 15px;
                                          height: 14px;
                                          display: inline-block;
                                          background-color: #fe0000;
                                          border-radius: 50px;
                                          text-align: center;
                                          line-height: 14px;
                                          vertical-align: middle;
                                          margin-right: 5px;
                                          color: #fff;
                                          font-size: 11px;
                                          margin-top: -3px;
                                          margin-left: 5px;
                                        "
                                      >
                                        N
                                      </span>
                                    </p>
                                  </td>
                                  <td
                                    style="
                                      text-align: right;
                                      width: 50%;
                                      position: relative;
                                      top: -36px;
                                      left: -20px;
                                    "
                                  >
                                    <p style="font-size: 12px; color: #4f4f4f">
                                      {{ irow.createdate }}
                                    </p>
                                  </td>
                                </tr>
                                <tr
                                  style="
                                    position: relative;
                                    top: -75px;
                                    left: -10px;
                                  "
                                >
                                  <td colspan="2" class="content-text">
                                    <span
                                      style="
                                        font-size: 15px;
                                        text-align: left;
                                        color: #4f4f4f;
                                      "
                                      >{{ irow.content }}</span
                                    >
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100per" v-if="task.total != 0">
          <div
            class="con-live main-table-padding"
            style="margin: 0px; margin-bottom: 70px"
          >
            <div class="title">
              <h3 class="bold">업무관리함</h3>
              <router-link to="/TaskManagement/taskList" class="more"
                >더보기</router-link
              >
            </div>
            <table class="table-col-main">
              <colgroup>
                <col width="124" />
                <col width="301" />
                <col width="158" />
                <col width="158" />
                <col width="100" />
                <col width="85" />
                <col width="100" />
              </colgroup>
              <thead>
                <tr>
                  <th>작성일</th>
                  <th>제목</th>
                  <th>본부</th>
                  <th>팀</th>
                  <th>작성자</th>
                  <th>중요도</th>
                  <th>진행상태</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(irow, i) in task.list"
                  :key="i"
                  @click="task.showDetail(irow.idx)"
                  class="pointer"
                >
                  <td class="txt-ellipsis" :title="irow.wdate">
                    {{ irow.wdate }}
                  </td>
                  <td class="txt-left txt-ellipsis" :title="irow.title">
                    {{ irow.title }}
                    <span
                      v-if="task.chkNewBtn(irow.wdate)"
                      class="my-box-list-new"
                      >N</span
                    >
                  </td>
                  <td class="txt-ellipsis" :title="irow.office">
                    {{ irow.office }}
                  </td>
                  <td class="txt-ellipsis" :title="irow.team">
                    {{ irow.team }}
                  </td>

                  <td
                    class="txt-ellipsis"
                    :title="irow.hq_ename + '(' + irow.hq_kname + ')'"
                  >
                    {{ irow.hq_ename }}({{ irow.hq_kname }})
                  </td>
                  <td class="txt-ellipsis" :title="irow.priority">
                    {{ irow.priority }}
                  </td>
                  <td :title="irow.progress_state">
                    {{ irow.progress_state }}
                  </td>
                </tr>
                <tr v-if="task.total == 0" class="txt-center">
                  <td colspan="5">결과가 없습니다.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="w-100per">
          <div
            class="con-live main-table-padding"
            style="margin: 0px; margin-bottom: 70px"
          >
            <div class="title">
              <h3 class="bold">결재처리 현황</h3>
              <router-link to="/documents/AllDocumentList" class="more"
                >더보기</router-link
              >
            </div>
            <table class="table-col-main">
              <colgroup>
                <col width="124" />
                <col width="184" />
                <col width="461" />
                <col width="152" />
                <col width="108" />
                <col width="136" />
              </colgroup>
              <thead>
                <tr>
                  <th>등록일</th>
                  <th>양식</th>
                  <th>제목</th>
                  <th>기안자</th>
                  <th>진행상태</th>
                  <th>본부/팀</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(irow, i) in docs.list"
                  :key="i"
                  @click="docs.showDetail(irow.idx)"
                  class="pointer"
                >
                  <td class="txt-ellipsis" :title="irow.cdate">
                    {{ irow.cdate }}
                  </td>
                  <td class="txt-ellipsis" :title="irow.form">
                    {{ irow.form }}
                  </td>
                  <td class="txt-left txt-ellipsis" :title="irow.title">
                    {{ irow.title }}
                    <span
                      v-if="docs.chkNewBtn(irow.cdate)"
                      class="my-box-list-new"
                      >N</span
                    >
                  </td>
                  <td
                    class="txt-ellipsis"
                    :title="irow.ename + '(' + irow.kname + ')'"
                  >
                    {{ irow.ename }}({{ irow.kname }})
                  </td>
                  <td class="txt-ellipsis" :title="irow.state_txt">
                    <span :class="{ 'col-red': irow.state == 'READY' }">{{
                      irow.state_txt
                    }}</span>
                  </td>
                  <td
                    class="txt-ellipsis"
                    :title="
                      irow.team_name
                        ? irow.team_name
                        : irow.office_name
                        ? irow.office_name
                        : '-'
                    "
                  >
                    {{
                      irow.team_name
                        ? irow.team_name
                        : irow.office_name
                        ? irow.office_name
                        : "-"
                    }}
                  </td>
                </tr>
                <tr v-if="docs.total == 0" class="txt-center">
                  <td colspan="6">결재 내역이 없습니다.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="w-100per">
          <div class="con-live main-table-padding" style="margin: 0px">
            <div class="title">
              <h3 class="bold">전략마케팅 처리 현황</h3>
              <router-link to="/customerManagement/statusSMPList" class="more"
                >더보기</router-link
              >
            </div>
            <table class="table-col-main">
              <colgroup>
                <col width="160" />
                <col width="387" />
                <col width="162" />
                <col width="209" />
                <col width="242" />
              </colgroup>
              <thead>
                <tr>
                  <th>접수일자</th>
                  <th>회사명</th>
                  <th>본부/팀 배정</th>
                  <th>배정일자</th>
                  <th>진행상태</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(irow, i) in smp.list"
                  :key="i"
                  @click="smp.showDetail(irow.idx)"
                  class="pointer"
                >
                  <td class="txt-ellipsis" :title="irow.receipt_date">
                    {{ irow.receipt_date }}
                  </td>
                  <td class="txt-ellipsis" :title="irow.companyname">
                    {{ irow.companyname }}
                    <span
                      v-if="smp.chkNewBtn(irow.receipt_date)"
                      class="my-box-list-new"
                      >N</span
                    >
                  </td>
                  <td
                    class="txt-ellipsis"
                    :title="
                      irow.team_name
                        ? irow.team_name
                        : irow.office_name
                        ? irow.office_name
                        : '-'
                    "
                  >
                    {{
                      irow.team_name
                        ? irow.team_name
                        : irow.office_name
                        ? irow.office_name
                        : "-"
                    }}
                  </td>

                  <td class="txt-ellipsis" :title="irow.process_date">
                    {{ irow.process_date }}
                  </td>
                  <td class="txt-ellipsis">
                    <span
                      v-if="irow.state == 'REQUEST'"
                      class="wait"
                      style="color: red"
                      >대기중</span
                    >
                    <span v-if="irow.state == 'PROCESS'">처리중</span>
                    <span v-if="irow.state == 'OPEN'" class="open">OPEN</span>
                    <span v-if="irow.state == 'FAIL'" class="fail">Fail</span>
                    <span v-if="irow.state == 'STOP'">홀딩</span>
                  </td>
                </tr>
                <tr v-if="smp.total == 0" class="txt-center">
                  <td colspan="5">전략마케팅 내역이 없습니다.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="con-stat">
        <div
          style="
            padding-top: 80px;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.12;
            letter-spacing: -0.48px;
            text-align: center;
            color: #4f4f4f;
          "
        >
          오늘의 캐럿 고객 현황입니다.
        </div>
        <ul class="pt-80">
          <li class="float-left mt-40">
            <!-- 랜덤이미지 01 -->
            <img
              src="@/assets/img/stat_logo.png"
              style="position: relative; bottom: -28px"
            />
            <!-- 랜덤이미지 02 -->
            <!-- <img src="@/assets/img/stat_logo02.png" style="position:relative; bottom:2px;"> -->
            <!-- 랜덤이미지 03 -->
            <!-- <img src="@/assets/img/stat_logo03.png" style="position:relative; bottom:0px;"> -->
          </li>
          <li class="float-left">
            <div
              v-if="store.state.idx_position <= 6104"
              style="
                margin-left: 17px;
                width: 288px;
                height: 259.3px;
                padding: 28.1px 44px 16.3px 36px;
                box-shadow: 4px -0.2px 15px 0 rgba(3, 3, 3, 0.08);
                background-color: #fff;
                border-top-left-radius: 50px;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
                border-bottom-left-radius: 10px;
              "
            >
              <p
                style="
                  text-align: center;
                  margin-bottom: 17px;
                  font-size: 17px;
                  font-weight: bold;
                  color: #4f4f4f;
                "
              >
                고객사 매출 순위
              </p>
              <router-link to="/customerManagement/statusCarrotTotalSalesList">
                <p
                  v-for="(irow, i) in sales.list"
                  :key="i"
                  class="pointer"
                  style="
                    text-align: left;
                    margin-bottom: 5px;
                    font-size: 15px;
                    color: #636262;
                  "
                >
                  <img
                    v-if="i == 0"
                    src="@/assets/img/sales_top1.webp"
                    style="width: 22px; height: 22px"
                  />
                  <img
                    v-if="i == 1"
                    src="@/assets/img/sales_top2.webp"
                    style="width: 22px; height: 22px"
                  />
                  <img
                    v-if="i == 2"
                    src="@/assets/img/sales_top3.webp"
                    style="width: 22px; height: 22px"
                  />
                  <img
                    v-if="i == 3"
                    src="@/assets/img/sales_top4.webp"
                    style="width: 22px; height: 22px"
                  />
                  <img
                    v-if="i == 4"
                    src="@/assets/img/sales_top5.webp"
                    style="width: 22px; height: 22px"
                  />
                  {{ irow.company_kname }}
                </p>
              </router-link>
              <p style="text-align: right">
                <router-link
                  to="/customerManagement/statusCarrotTotalSalesList"
                  class="more"
                  ><i class="fas fa-plus"></i
                ></router-link>
              </p>
            </div>
            <div
              v-if="store.state.idx_position > 6104"
              style="
                margin-left: 17px;
                width: 288px;
                height: 259.3px;
                padding: 20px;
                box-shadow: 4px -0.2px 15px 0 rgba(3, 3, 3, 0.08);
                background-color: #fff;
                border-top-left-radius: 50px;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
                border-bottom-left-radius: 10px;
              "
            >
              <!-- staff 랜덤 글 01 -->
              <div v-if="main.randNum == 0">
                <p
                  style="
                    text-align: center;
                    font-size: 32px;
                    padding-top: 24px;
                    font-weight: bold;
                    color: #4f4f4f;
                    line-height: 42px;
                  "
                  class="carrot-message font-g"
                >
                  1 Spirit!<br />최고의 정신
                </p>
                <p style="font-size: 18px; margin-top: 10px">
                  최고의 일터를 만들고 최고의 삶을 함께<br />만들어 나가요!
                </p>
              </div>
              <!-- staff 랜덤 글 02 -->
              <div v-if="main.randNum == 1">
                <p
                  style="
                    text-align: center;
                    font-size: 32px;
                    font-weight: bold;
                    color: #4f4f4f;
                    line-height: 42px;
                  "
                  class="carrot-message font-g"
                >
                  1 Goal!<br />우리의 존재 이유<br />바로 고객!
                </p>
                <p style="font-size: 18px; margin-top: 10px">
                  우리의 행동과 판단은<br />항상 북극성처럼<br />고객을
                  향합니다!
                </p>
              </div>
              <!-- staff 랜덤 글 03 -->
              <div v-if="main.randNum == 2">
                <p
                  style="
                    text-align: center;
                    font-size: 32px;
                    font-weight: bold;
                    color: #4f4f4f;
                    line-height: 42px;
                  "
                  class="carrot-message font-g"
                >
                  1 Team!<br />팀을 통해 성장한다.
                </p>
                <p style="font-size: 18px; margin-top: 10px">
                  우리는 팀 웍을 통해<br />더 큰 나, 더 큰 우리를<br />실현합니다.
                </p>
              </div>
            </div>
          </li>

          <li class="float-left" style="position: relative; top: -40px">
            <img src="@/assets/img/stat_carrotglobal.png" class="img-title" />
            <div class="carrotglobal">
              <div style="float: left; width: 24%">
                <p
                  style="
                    font-family: MalgunGothic;
                    font-size: 14px;
                    line-height: 2.2;
                    letter-spacing: -0.28px;
                    text-align: center;
                    color: #fff;
                  "
                >
                  고객사 수
                </p>

                <p
                  style="font-size: 25px; font-weight: bold; color: #fff"
                  class="font-g"
                >
                  <vue3-autocounter
                    ref="counter"
                    :startAmount="0"
                    :endAmount="statistics.itm.company_cnt"
                    :duration="3"
                    separator=","
                    :autoinit="true"
                  />
                </p>
              </div>
              <p
                style="
                  float: left;
                  width: 1px;
                  height: 50px;
                  margin-top: 10px;
                  background: #ffffff;
                "
              ></p>
              <div style="float: left; width: 24%">
                <p
                  style="
                    font-family: MalgunGothic;
                    font-size: 14px;
                    line-height: 2.2;
                    letter-spacing: -0.28px;
                    text-align: center;
                    color: #fff;
                  "
                >
                  Class 수
                </p>
                <p
                  style="font-size: 25px; font-weight: bold; color: #fff"
                  class="font-g"
                >
                  <vue3-autocounter
                    ref="counter"
                    :startAmount="0"
                    :endAmount="statistics.itm.class_cnt"
                    :duration="3"
                    separator=","
                    :autoinit="true"
                  />
                </p>
              </div>
              <p
                style="
                  float: left;
                  width: 1px;
                  height: 50px;
                  margin-top: 10px;
                  background: #ffffff;
                "
              ></p>
              <div style="float: left; width: 24%">
                <p
                  style="
                    font-family: MalgunGothic;
                    font-size: 14px;
                    line-height: 2.2;
                    letter-spacing: -0.28px;
                    text-align: center;
                    color: #fff;
                  "
                >
                  학습자 수
                </p>
                <p
                  style="font-size: 25px; font-weight: bold; color: #fff"
                  class="font-g"
                >
                  <vue3-autocounter
                    ref="counter"
                    :startAmount="0"
                    :endAmount="statistics.itm.customer_cnt"
                    :duration="3"
                    separator=","
                    :autoinit="true"
                  />
                </p>
              </div>
              <p
                style="
                  float: left;
                  width: 1px;
                  height: 50px;
                  margin-top: 10px;
                  background: #ffffff;
                "
              ></p>
              <div style="float: left; width: 24%">
                <p
                  style="
                    font-family: MalgunGothic;
                    font-size: 14px;
                    line-height: 2.2;
                    letter-spacing: -0.28px;
                    text-align: center;
                    color: #fff;
                  "
                >
                  강사 수
                </p>
                <p
                  style="font-size: 25px; font-weight: bold; color: #fff"
                  class="font-g"
                >
                  <vue3-autocounter
                    ref="counter"
                    :startAmount="0"
                    :endAmount="statistics.itm.tutor_cnt"
                    :duration="3"
                    separator=","
                    :autoinit="true"
                  />
                </p>
              </div>
            </div>
            <img
              src="@/assets/img/stat_carrotsolutions.png"
              class="img-title"
            />
            <div class="carrotsolutions">
              <div style="float: left; width: 24%">
                <p
                  style="
                    font-family: MalgunGothic;
                    font-size: 14px;
                    line-height: 2.2;
                    letter-spacing: -0.28px;
                    text-align: center;
                    color: #fff;
                  "
                >
                  고객사 수
                </p>
                <p
                  style="font-size: 25px; font-weight: bold; color: #fff"
                  class="font-g"
                >
                  <vue3-autocounter
                    ref="counter"
                    :startAmount="0"
                    :endAmount="statistics.ctm.company_cnt"
                    :duration="3"
                    separator=","
                    :autoinit="true"
                  />
                </p>
              </div>
              <p
                style="
                  float: left;
                  width: 1px;
                  height: 50px;
                  margin-top: 10px;
                  background: #ffffff;
                "
              ></p>
              <div style="float: left; width: 24%">
                <p
                  style="
                    font-family: MalgunGothic;
                    font-size: 14px;
                    line-height: 2.2;
                    letter-spacing: -0.28px;
                    text-align: center;
                    color: #fff;
                  "
                >
                  학습자 수
                </p>
                <p
                  style="font-size: 25px; font-weight: bold; color: #fff"
                  class="font-g"
                >
                  <vue3-autocounter
                    ref="counter"
                    :startAmount="0"
                    :endAmount="statistics.ctm.customer_cnt"
                    :duration="3"
                    separator=","
                    :autoinit="true"
                  />
                </p>
              </div>
              <p
                style="
                  float: left;
                  width: 1px;
                  height: 50px;
                  margin-top: 10px;
                  background: #ffffff;
                "
              ></p>
              <div style="float: left; width: 24%">
                <p
                  style="
                    font-family: MalgunGothic;
                    font-size: 14px;
                    line-height: 2.2;
                    letter-spacing: -0.28px;
                    text-align: center;
                    color: #fff;
                  "
                >
                  강사 수
                </p>
                <p
                  style="font-size: 25px; font-weight: bold; color: #fff"
                  class="font-g"
                >
                  <vue3-autocounter
                    ref="counter"
                    :startAmount="0"
                    :endAmount="statistics.ctm.tutor_cnt"
                    :duration="3"
                    separator=","
                    :autoinit="true"
                  />
                </p>
              </div>
              <p
                style="
                  float: left;
                  width: 1px;
                  height: 50px;
                  margin-top: 10px;
                  background: #ffffff;
                "
              ></p>
              <div style="float: left; width: 24%">
                <p
                  style="
                    font-family: MalgunGothic;
                    font-size: 14px;
                    line-height: 2.2;
                    letter-spacing: -0.28px;
                    text-align: center;
                    color: #fff;
                  "
                >
                  오늘의 수업 만족도
                </p>
                <p
                  style="font-size: 25px; font-weight: bold; color: #fff"
                  class="font-g"
                >
                  <vue3-autocounter
                    ref="counter"
                    :startAmount="0"
                    :endAmount="statistics.ctm.learning_satisfaction"
                    :duration="3"
                    separator=","
                    decimalSeparator="."
                    :decimals="2"
                    :autoinit="true"
                  /><span style="color: #ffd1ea; font-size: 19px">/5.0</span>
                </p>
              </div>
            </div>
          </li>
          <div class="wrap" style="position: relative; top: -70px">
            <div class="con">
              <div class="tab">
                <ul>
                  <li
                    :class="{
                      active: 1 == main.isTab,
                      is_lead: store.state.position == '리드',
                    }"
                    class="pointer"
                  >
                    <div @click="main.setTab(1)" class="dp-inblock w-90per">
                      고객사 DB/상담일지
                    </div>
                    <router-link
                      to="/customerManagement/customerDBList"
                      class="dp-inblock w-5per"
                      ><i class="fas fa-plus font-12"></i
                    ></router-link>
                  </li>
                  <li
                    :class="{
                      active: 2 == main.isTab,
                      is_lead: store.state.position == '리드',
                    }"
                    class="pointer"
                  >
                    <div @click="main.setTab(2)" class="dp-inblock w-90per">
                      현장경영 스케줄
                    </div>
                    <router-link
                      to="/customerManagement/scheduleVisitWeekly"
                      class="dp-inblock w-5per"
                      ><i class="fas fa-plus font-12"></i
                    ></router-link>
                  </li>
                  <li
                    :class="{
                      active: 3 == main.isTab,
                      is_lead: store.state.position == '리드',
                    }"
                    class="pointer"
                  >
                    <div @click="main.setTab(3)" class="dp-inblock w-90per">
                      명함관리
                    </div>
                    <router-link
                      to="/customerManagement/businessCardList"
                      class="dp-inblock w-5per"
                      ><i class="fas fa-plus font-12"></i
                    ></router-link>
                  </li>
                </ul>
              </div>
              <div class="container">
                <div
                  id="tab1"
                  class="tab-content"
                  v-if="main.isTab == 1"
                  style="min-height: 330px"
                >
                  <table
                    class="mt-20"
                    @mouseover="bbs.isTabMouseOver = 1"
                    @mouseleave="bbs.isTabMouseOver = 0"
                  >
                    <thead>
                      <tr>
                        <th>작성일</th>
                        <th width="60px">단계</th>
                        <th>HR담당자</th>
                        <th>고객사명</th>
                        <th>상담일지</th>
                        <th>HQ매니저</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(irow, i) in main.list"
                        :key="i"
                        @click="main.showDetail(irow)"
                        class="pointer"
                      >
                        <td width="100" class="txt-gray txt-right">
                          {{ irow.wdate }}
                        </td>
                        <td>{{ irow.step }}</td>
                        <td width="120">
                          <div
                            style="
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              width: 120px;
                              height: 24px;
                            "
                          >
                            {{ irow.mgr_name }}
                          </div>
                        </td>
                        <td width="120">
                          <div
                            style="
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              width: 120px;
                              height: 24px;
                            "
                          >
                            {{ irow.kname }}
                            <span
                              v-if="bbs.chkNewBtn(irow.wdate)"
                              class="my-box-list-new"
                              >N</span
                            >
                          </div>
                        </td>
                        <td>
                          <div
                            @mouseover="bbs.isShow = irow.idx_schedule"
                            @mouseleave="bbs.isShow = 0"
                            class="txt-s1 cal-item txt-left"
                          >
                            <div
                              style="
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                width: 500px;
                                font-size: 14px;
                                line-height: 14px;
                                height: 14px;
                                position: relative;
                              "
                            >
                              <span v-html="irow.contents"></span>
                            </div>
                            <div
                              v-html="irow.contents"
                              v-if="
                                bbs.isShow == irow.idx_schedule &&
                                irow.contents != ''
                              "
                              style="
                                position: absolute;
                                z-index: 555;
                                min-width: 500px;
                                max-width: 500px;
                                overflow: hidden;
                                min-height: 205px;
                                max-height: 205;
                                background-color: #ffffff;
                                border: 1px solid #ddd;
                                padding: 5px 11px;
                                border-radius: 1px;
                                font-size: 14px;
                                font-weight: normal;
                                line-height: 1.64;
                                text-align: left;
                                color: #555555;
                                word-break: break-all;
                                word-break: break-all;
                                display: -webkit-box;
                                word-wrap: break-word;
                                -webkit-line-clamp: 9;
                                -webkit-box-orient: vertical;
                                text-overflow: ellipsis;
                              "
                            ></div>
                          </div>
                        </td>
                        <td>
                          {{ irow.hq_ename }}
                          <span v-if="irow.hq_kname != null"
                            >({{ irow.hq_kname }})</span
                          >
                          <span v-if="irow.hq_kname == null">-</span>
                        </td>
                      </tr>
                      <tr v-if="main.total == 0">
                        <td colspan="6">고객사 DB/상담일지가 없습니다.</td>
                      </tr>
                    </tbody>
                  </table>
                  <router-link
                    to="/customerManagement/customerDBList"
                    class="more"
                    >더보기</router-link
                  >
                </div>
                <div
                  id="tab2"
                  class="tab-content"
                  v-if="main.isTab == 2"
                  style="min-height: 310px"
                >
                  <table
                    class="mt-40 table-col table-cal"
                    style=""
                    @mouseover="bbs.isTabMouseOver = 2"
                    @mouseleave="bbs.isTabMouseOver = 0"
                  >
                    <colgroup>
                      <col span="7" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>SUN</th>
                        <th>MON</th>
                        <th>TUE</th>
                        <th>WED</th>
                        <th>THU</th>
                        <th>FRI</th>
                        <th>SAT</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th
                          v-for="(irow, i) in bbs.list"
                          :key="i"
                          :class="{ today: irow.vdate == bbs.today }"
                        >
                          {{ irow.vdate }}
                        </th>
                      </tr>
                      <tr>
                        <td
                          v-for="(irow, i) in bbs.list"
                          :key="i"
                          :class="{ today: irow.vdate == bbs.today }"
                        >
                          <template v-for="(jrow, j) in irow.times" :key="j">
                            <div
                              v-if="j < 6"
                              :class="{
                                'txt-s1': jrow.state == 'READY',
                                'txt-s2': jrow.state == 'DONE',
                                'txt-s3': jrow.state == 'VISIT',
                              }"
                              @mouseover="bbs.isShow = jrow.idx"
                              @mouseleave="bbs.isShow = 0"
                              class="cal-item txt-left"
                            >
                              <div
                                @click="bbs.showSchedule(jrow.idx)"
                                style="position: relative"
                              >
                                <div
                                  style="
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    width: 120px;
                                  "
                                  class="pointer"
                                >
                                  {{ jrow.hq_ename }} : {{ jrow.company_kname }}
                                </div>
                                <div
                                  v-if="bbs.isShow == jrow.idx"
                                  style="
                                    position: absolute;
                                    min-width: 200px;
                                    max-width: 300px;
                                    background-color: #f9f9f9;
                                    border: 1px solid #ddd;
                                    padding: 5px 11px;
                                    border-radius: 1px;
                                    font-size: 14px;
                                    font-weight: normal;
                                    line-height: 1.64;
                                    text-align: left;
                                    color: #555555;
                                    word-break: break-all;
                                    z-index: 555;
                                  "
                                >
                                  <!-- v-if="bbs.isShow==jrow.idx" -->
                                  매니저 : {{ jrow.hq_ename }}({{
                                    jrow.hq_kname
                                  }})<br />
                                  방문일시 : {{ jrow.vdate }} {{ jrow.vtime
                                  }}<br />
                                  고객사 : {{ jrow.company_kname }}
                                </div>
                              </div>
                            </div>
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <router-link
                    to="/customerManagement/scheduleVisitWeekly"
                    class="more"
                    >더보기</router-link
                  >
                </div>

                <div
                  id="tab3"
                  class="tab-content"
                  v-if="main.isTab == 3"
                  style="min-height: 330px"
                >
                  <table
                    class="mt-20"
                    @mouseover="bbs.isTabMouseOver = 3"
                    @mouseleave="bbs.isTabMouseOver = 0"
                  >
                    <thead>
                      <tr>
                        <th>등록일</th>
                        <th>고객사</th>
                        <th>고객사유형</th>
                        <th>단계</th>
                        <th>이름</th>
                        <th>직급</th>
                        <th>HQ매니저</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(irow, i) in main.list"
                        :key="i"
                        @click="main.showDetail(irow)"
                        class="pointer"
                      >
                        <td class="w-120px txt-gray txt-center">
                          {{ irow.wdate }}
                        </td>
                        <td class="txt-left">
                          {{ irow.company_kname }}
                          <span
                            v-if="bbs.chkNewBtn(irow.wdate)"
                            class="my-box-list-new"
                            >N</span
                          >
                        </td>
                        <td>{{ irow.company_type }}</td>
                        <td>{{ irow.step }}</td>
                        <td>{{ irow.kname }}</td>
                        <td>{{ irow.position }}</td>
                        <td>
                          {{ irow.hq_ename }}
                          <span v-if="irow.hq_kname != null"
                            >({{ irow.hq_kname }})</span
                          >
                          <span v-if="irow.hq_kname == null">-</span>
                        </td>
                      </tr>
                      <tr v-if="main.total == 0">
                        <td colspan="7">명함 정보가 없습니다.</td>
                      </tr>
                    </tbody>
                  </table>
                  <router-link
                    to="/customerManagement/businessCardList"
                    class="more"
                    >더보기</router-link
                  >
                </div>
                <div id="tab4" class="tab-content" v-if="main.isTab == 4">
                  <table class="mt-20">
                    <tbody>
                      <tr
                        v-for="(irow, i) in main.list"
                        :key="i"
                        @click="main.showDetail(irow)"
                        class="pointer"
                      >
                        <td class="bold" width="80">{{ irow.rank }}</td>
                        <td class="txt-left">{{ irow.company_kname }}</td>
                        <td class="w-120px txt-right">
                          {{ main.number_format(irow.tot_sales) }} 원
                        </td>
                      </tr>
                      <tr v-if="main.total == 0">
                        <td colspan="3">매출 정보가 없습니다.</td>
                      </tr>
                    </tbody>
                  </table>
                  <router-link
                    to="/customerManagement/statusCarrotTotalSalesList"
                    class="more"
                    >더보기</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </ul>
      </div>
      <!--
          <div class="con-holiday">
            <h2 class="bold">CARROTians 휴가/교육 현황 By Week</h2>
            <p @click="main.moveLink('myPIMS-AllVacationList')" class="txt-right w-100per mt-10 mb-10 font-16 pointer">더보기 </p>
            <div class="calendar-wrap" style="position:relative; top:-50px;">
              <div v-for="(irow, i) in main.dates" :key="i" class="calendar">
                <p>{{ irow.date }} <span class="day">{{ irow.day }}</span></p>
                <ul>
                  <template v-for="(jrow, j) in main.elist" :key="j">
                    <li v-if="irow.cdate==jrow.cdate" class="special-book">
                      {{ jrow.text }}
                    </li>
                  </template>
                  <template v-for="(jrow, j) in main.vlist" :key="j">
                    <li v-if="irow.cdate==jrow.cdate">
                      {{ jrow.text }}<span class="txt-orange"> - {{ jrow.type }}</span>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
          -->
      <div class="con-holiday">
        <h2 class="bold">CARROTians 휴가/교육 현황 By Week</h2>
        <p
          @click="main.moveLink('myPIMS-AllVacationList')"
          class="txt-right w-100per mt-10 mb-10 font-16 pointer"
        >
          더보기
        </p>
        <div class="calendar-wrap">
          <div
            v-for="(irow, i) in main.dates"
            :key="i"
            class="calendar"
            :class="{ today: irow.date == main.today }"
          >
            <p :class="{ 'txt-red': 'Y' == irow.holiday }">
              {{ irow.date }}
              <span class="day" :class="{ 'txt-red': 'Y' == irow.holiday }"
                >{{ irow.day }} {{ irow.title }}</span
              >
            </p>
            <p style="display: none">{{ (cnt = 0) }}</p>
            <ul>
              <template v-for="(jrow, j) in main.elist" :key="j">
                <p v-if="irow.cdate == jrow.cdate" style="display: none">
                  {{ (cnt = cnt + 1) }}
                </p>
                <li
                  v-if="irow.cdate == jrow.cdate && cnt < 5"
                  class="special-book"
                  @mouseover="main.isDateMouseOver = i"
                  @mouseleave="main.isDateMouseOver = 999"
                >
                  {{ jrow.text }}
                </li>
              </template>
              <template v-for="(jrow, j) in main.vlist" :key="j">
                <p v-if="irow.cdate == jrow.cdate" style="display: none">
                  {{ (cnt = cnt + 1) }}
                </p>
                <li
                  v-if="
                    irow.cdate == jrow.cdate &&
                    cnt < 5 &&
                    main.isDateMouseOver !== i
                  "
                  @mouseover="main.isDateMouseOver = i"
                  @mouseleave="main.isDateMouseOver = 999"
                >
                  {{ jrow.text
                  }}<span class="txt-orange"> - {{ jrow.type }} </span>
                </li>
              </template>

              <div
                v-if="main.isDateMouseOver == i"
                :class="{
                  today: irow.date == main.today,
                  'bg-white': irow.date != main.today,
                }"
                @mouseleave="main.isDateMouseOver = 999"
                style="position: relative; z-index: 99"
              >
                <template v-for="(jrow, j) in main.elist" :key="j">
                  <li v-if="irow.cdate == jrow.cdate" class="special-book">
                    {{ jrow.text }}
                  </li>
                </template>
                <template v-for="(jrow, j) in main.vlist" :key="j">
                  <li v-if="irow.cdate == jrow.cdate">
                    {{ jrow.text
                    }}<span class="txt-orange"> - {{ jrow.type }}</span>
                  </li>
                </template>
              </div>
            </ul>
            <!-- 화살표는 5명 이상 일 경우 노출 -->
            <div v-if="cnt >= 5" class="txt-center">
              <i
                v-if="main.isDateMouseOver != i"
                class="fas fa-chevron-down"
              ></i>
            </div>
          </div>
        </div>
      </div>

      <div class="con-ceo">
        <ul class="pt-80">
          <li class="float-left mt-20">
            <img src="@/assets/img/ceo-img.png" alt="ceo" />
          </li>
          <router-link
            :to="{
              name: 'CARROTZone-CEOMessageView-idx',
              params: { idx: main.info.ceomessage.idx },
            }"
            target="_blank"
          >
            <li v-if="main.info" class="ceo-message float-left w-510px">
              <span>CEO HOT Line</span>
              <p class="title">
                {{ main.info.ceomessage.title }}
                <span
                  v-if="main.chkNewBtn7days(main.info.ceomessage.wdate)"
                  class="my-box-list-new_ceo"
                  >N</span
                >
              </p>
              <div class="sub" v-html="main.info.ceomessage.summary"></div>
            </li>
          </router-link>
          <p class="more float-right underline" style="text-align: right">
            <router-link
              :to="{
                name: 'CARROTZone-CEOMessageView-idx',
                params: { idx: main.info.ceomessage.idx },
              }"
              target="_blank"
              >자세히 보기</router-link
            >
          </p>
        </ul>
      </div>
      <div class="con-media-board">
        <div class="con-media-board-wrap">
          <div class="con-media-board-div">
            <div class="con-media-board-txt">
              <h2 class="bold">#Meet_and_Greet</h2>
              <p>
                지난 7일간 가장 인기있었던 글을<br />
                만나보세요!
              </p>
            </div>
            <div
              class="con-media-board-img"
              v-if="mediazoneTopList.list.length > 0"
            >
              <carousel
                :items-to-show="3"
                :autoplay="3000"
                :wrap-around="true"
                :autoplay-hover-pause="false"
              >
                <slide
                  v-for="(irow, slide) in mediazoneTopList.list"
                  :key="slide"
                >
                  <router-link
                    :to="{
                      name: 'CARROTZone-MediaZoneView-idx',
                      params: { idx: irow.idx },
                    }"
                  >
                    <div>
                      <div
                        style="
                          width: 440.3px;
                          height: 330.3px;
                          overflow: hidden;
                          background: #ffffff;
                          border-top-right-radius: 50px;
                          border-top-left-radius: 50px;
                        "
                      >
                        <img
                          style="
                            width: 100%;
                            width: 440.3px;
                            height: 330.3px;
                            border-top-right-radius: 50px;
                            border-top-left-radius: 50px;
                            vertical-align: middle;
                            object-fit: cover;
                            object-position: top;
                          "
                          :src="irow.thumb"
                        />
                      </div>
                      <div
                        style="
                          width: 440.3px;
                          height: 180px;
                          background-color: #fff;
                          border-bottom-right-radius: 50px;
                          border-bottom-left-radius: 50px;
                        "
                      >
                        <p
                          style="
                            padding-top: 20px;
                            height: 63px;
                            font-size: 22px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.55;
                            letter-spacing: -0.44px;
                            text-align: center;
                            color: #282828;
                          "
                        >
                          {{ irow.title }}
                        </p>
                        <p
                          style="
                            padding-top: 40px;
                            font-size: 16px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.56;
                            letter-spacing: -0.32px;
                            text-align: center;
                            color: #282828;
                          "
                        >
                          <img
                            style="
                              width: 44px;
                              height: 44px;
                              border-radius: 200px;
                            "
                            :src="irow.thumb"
                          />
                          {{ irow.ename }}({{ irow.kname }})
                        </p>
                      </div>
                    </div>
                  </router-link>
                </slide>

                <template #addons>
                  <navigation />
                </template>
              </carousel>
            </div>
          </div>
        </div>

        <div class="media-new-wrap">
          <p
            class="txt-right w-100per mb-10 font-16 pointer"
            style="position: relative; right: 40px"
          >
            <router-link
              :to="{ name: 'CARROTZone-MediaZoneList' }"
              class="dp-block"
              >더보기
            </router-link>
          </p>
          <div v-if="main.isLoaded == true" class="board-media">
            <div
              v-for="(irow, i) in main.mediazone.list"
              :key="i"
              class="media-con"
              style="border: 1px solid #e6e6e6"
            >
              <ul>
                <li class="con-title mt-15" style="text-align: left">
                  <img
                    @mouseover="main.isShowImg = i"
                    @mouseleave="main.isShowImg = 999"
                    style="
                      margin-left: 10px;
                      margin-right: 12px;
                      width: 44px;
                      height: 44px;
                      border-radius: 200px;
                    "
                    :src="irow.photouri"
                  />
                  <span
                    @mouseover="main.isShowImg = i"
                    @mouseleave="main.isShowImg = 999"
                    >{{ irow.ename }}({{ irow.kname }})</span
                  >
                  <img
                    v-if="irow.is_today == 'Y' && irow.is_new == 'Y'"
                    style="float: right"
                    src="@/assets/img/media-new.png"
                  />

                  <div class="txt-s1 cal-item txt-left">
                    <div
                      v-if="main.isShowImg == i && irow.photouri != ''"
                      class="hover-img"
                    >
                      <img
                        style="width: 100%; height: 100%"
                        :src="irow.photouri"
                      />
                    </div>
                  </div>
                </li>

                <router-link
                  :to="{
                    name: 'CARROTZone-MediaZoneView-idx',
                    params: { idx: irow.idx },
                  }"
                >
                  <li class="con-img pointer">
                    <img :src="irow.thumb" :alt="irow.title" />
                  </li>
                </router-link>
                <div class="mt-20 comment-list">
                  <div class="title-box type1">
                    <span
                      class="tit"
                      @click="bbs_comment.setGood(irow.idx)"
                    ></span>
                    <span
                      class="pointer"
                      @click="bbs_comment.setGood(irow.idx)"
                      style="color: #fd6011; font-size: 15px; line-height: 2.07"
                    >
                      <img
                        v-if="irow.is_good == 'Y'"
                        src="@/assets/img/media-like-on.png"
                      />
                      <img
                        v-if="irow.is_good == 'N'"
                        src="@/assets/img/media-like.png"
                      />
                      {{ irow.good }}
                    </span>
                    <span
                      style="
                        color: #fd6011;
                        font-size: 15px;
                        line-height: 2.07;
                        padding-left: 13px;
                      "
                    >
                      <img
                        src="@/assets/img/media-comment.png"
                        style="position: relative; bottom: 1px"
                      />
                      {{ irow.cnt_cmt }}
                    </span>
                  </div>
                </div>
                <li class="con-title">
                  <router-link
                    :to="{
                      name: 'CARROTZone-MediaZoneView-idx',
                      params: { idx: irow.idx },
                    }"
                  >
                    <p class="title bold">
                      <span class="con-title" style="font-size: 16px">{{
                        irow.title
                      }}</span>
                      <!--새로운게시물이 올라왔을 때-->
                    </p>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div id="quick-menu" class="con-quick-menu-wrap">
          <h2 class="bold">QUICK MENU</h2>
          <div class="icon-wrap">
            <div class="icon-div">
              <p class="bold">부탁해요 업무협조 게시판</p>
              <ul>
                <li
                  @click="
                    main.moveExternalLink(
                      'https://carrotsds.atlassian.net/servicedesk/customer/portal/8'
                    )
                  "
                >
                  <img
                    src="@/assets/img/icon-01_new.svg"
                    alt="솔루션개발 업무협조"
                  />
                  <span>솔루션개발<br />업무협조</span>
                </li>
                <li
                  @click="
                    main.moveExternalLink(
                      'https://carrotsds.atlassian.net/servicedesk/customer/portal/9'
                    )
                  "
                >
                  <img src="@/assets/img/icon-03_new.svg" alt="" />
                  <span>컨텐츠플랫폼<br />업무협조</span>
                </li>
                <li
                  @click="
                    main.moveLink('BusinessManagement-ManagementPlanList')
                  "
                >
                  <img src="@/assets/img/icon-02_new.svg" alt="" />
                  <span>경영기획<br />업무협조</span>
                </li>

                <li>
                  <a @click="main.goImageEditor" target="_blank">
                    <img src="@/assets/img/icon-15_new.svg" alt="" />
                    <span>이미지맵<br />링크에디터</span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="icon-div">
              <p class="bold">함께 쓰는 캐럿자산</p>
              <ul>
                <li
                  @click="main.moveLink('documents-SalesManagementPublishList')"
                >
                  <img
                    src="@/assets/img/icon-05_new.svg"
                    alt=""
                    style="width: 55px"
                  />
                  <span>세금계산서<br />발행 등록 관리</span>
                </li>
                <li
                  @click="
                    main.moveLink('ResourceManagement-VehicleReservationLogDay')
                  "
                >
                  <img src="@/assets/img/icon-06_new.svg" alt="" />
                  <span>차량운행 <br />예약일지</span>
                </li>
                <li
                  @click="
                    main.moveLink('ResourceManagement-ClassroomReservationDate')
                  "
                >
                  <img src="@/assets/img/icon-07_new.svg" alt="" />
                  <span>글로벌센터<br />예약 현황</span>
                </li>
                <li
                  @click="
                    main.moveExternalLink(
                      'https://calendar.google.com/calendar/u/0?cid=aW1yMzBxODVtOTl2OXBoNG5lbWJmb2dqazBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ'
                    )
                  "
                >
                  <img src="@/assets/img/icon-14_new.png" alt="" />
                  <span>CARROT 교육/행사<br />연간 스케줄러</span>
                </li>
              </ul>
            </div>
            <div class="icon-div">
              <p class="bold">도와줘요 Manual</p>
              <ul>
                <li @click="main.moveLink('CARROTZone-CarrotManualList')">
                  <img src="@/assets/img/icon-09_new.svg" alt="" />
                  <span>CARROT<br />Manual</span>
                </li>
                <li>
                  <a @click="main.goDelivery" target="_blank">
                    <img src="@/assets/img/icon-10_new.svg" alt="" />
                    <span>택배발송<br />예약하기</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://carrotglobal-my.sharepoint.com/:x:/p/roomikim/Efa-H9a8rSBLsLx6kTG8ypoBLjQVJJnfJVoyOnBWgEFE1g?e=rnoZzg"
                    target="_blank"
                  >
                    <img src="@/assets/img/icon-11_new.svg" alt="" />
                    <span>CARROT 교재<br />오류 신고함</span>
                  </a>
                </li>
                <li>
                  <a
                    href="http://old.carrotians.net/intranet_snew/email_template.html"
                    target="_blank"
                  >
                    <img src="@/assets/img/icon-12_new.svg" alt="" />
                    <span>CARROT 업무<br />(영문)</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="con-edu">
            <ul>
              <li>
                <p class="main-title">
                  성장해 나가는 <br /><span class="txt-orange">SMART</span>한
                  <br />캐러시안!
                </p>
              </li>
              <li
                class="pointer"
                onclick="window.open('https://carrot.hunet.co.kr/Primelogin/LoginGate')"
              >
                <p class="img">
                  <img src="@/assets/img/carrot_edu_1.png" alt="edu" />
                </p>
                <p class="title bold font-15">On-line ACADEMY ></p>
                <p class="sub">캐러시안의 성장을<br />견인하는 온라인 수업!</p>
              </li>
              <li
                class="pointer"
                onclick="window.open('http://old.carrotians.net/library/')"
              >
                <p class="img">
                  <img src="@/assets/img/carrot_edu_2.png" alt="edu" />
                </p>
                <p class="title bold font-15">LIBRARY ></p>
                <p class="sub">캐럿의 모든 컨텐츠가<br />모여있는 곳!</p>
              </li>
              <li
                class="pointer"
                onclick="window.open('https://carrotians.imooc.co.kr/bmain')"
              >
                <p class="img">
                  <img src="@/assets/img/carrot_edu_3.png" alt="edu" />
                </p>
                <p class="title bold font-15">i:MOOC ></p>
                <p class="sub">가치의 확장,<br />지식러닝의 혁신!</p>
              </li>
              <li
                class="pointer"
                onclick="window.open('/leadership', 'CARROT Leadership 지원 Button 제도','width=600,height=750,location=no,status=no,scrollbars=yes')"
              >
                <p class="img">
                  <img src="@/assets/img/carrot_edu_4.png" alt="edu" />
                </p>
                <p class="title bold font-15">
                  <span class="txt-orange">캐럿 리더십</span> 지원 >
                </p>
                <p class="sub">CARROT의 Lead로 성장하고 싶다면?</p>
              </li>
            </ul>
          </div>
        </div>

        <div class="footer">
          <h3>
            서로에게서 최고의 것을 발견하고, <br />
            최고의 것을 주는 사람들, 우리는 CARROT입니다.
          </h3>
          <ul>
            <li>
              <a
                href="https://www.instagram.com/carrotenglish/"
                target="_blank"
              >
                <img src="@/assets/img/ico_social1.png" /><br />
                당근영어
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/carrotglobal/" target="_blank">
                <img src="@/assets/img/ico_social1.png" /><br />
                캐럿글로벌
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/thecarrotglobal"
                target="_blank"
              >
                <img src="@/assets/img/ico_social2.png" /><br />
                페이스북
              </a>
            </li>
            <li>
              <a href="https://blog.naver.com/carrot_global" target="_blank">
                <img src="@/assets/img/ico_social3.png" /><br />
                블로그
              </a>
            </li>
          </ul>
          <div class="txt-right">
            <span class="txt-black pointer stage-site" @click="main.goStage"
              >테스트 서버</span
            >
            <span
              class="txt-black pointer familyadmin-site"
              @click="main.gofamilysite_admin"
              >Family Site & Admin</span
            >
            <span class="txt-black pointer inner-plan" @click="main.goInnerPlan"
              >&nbsp;&nbsp;&nbsp;내부관리계획서</span
            >
          </div>
        </div>
      </div>
    </div>

    <!-- judy님 추모 팝업 -->
    <div v-if="showPopup" class="judy-popup">
      <div>
        <div class="inner">
          <div class="close">
            <img
              @click="closePopup"
              src="@/assets/img/close-877.png"
              alt="icon"
              class="judy-close"
            />
          </div>
          <img src="@/assets/img/judy.png" alt="img" class="judy-img" />
          <div class="btn-list">
            <p @click="closeToday" class="close-today">오늘 하루 보지않기</p>
            <a
              href="https://carrotians.net/CARROTZone/MediaZoneView/908"
              class="go"
              >바로가기</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import axios from "@/plugins/axios.js";
import { useStore } from "vuex";
import ThanksDollarViewPopup from "@/components/popup/personality/ThanksDollarViewPopup.vue";
import ThanksDollarListPopup from "@/components/popup/personality/ThanksDollarListPopup.vue";
import MCRMCGAddInfoPopup from "@/components/popup/myPIMS/MCRMCGAddInfoPopup.vue";
import Swal from "sweetalert2";
import Vue3autocounter from "vue3-autocounter";
//import $ from 'jquery';

import "@/assets/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";

export default {
  name: "Main",
  components: {
    ThanksDollarViewPopup,
    ThanksDollarListPopup,
    "vue3-autocounter": Vue3autocounter,
    Carousel,
    Slide,
    Navigation,
    MCRMCGAddInfoPopup,
  },
  setup() {
    const router = useRouter();
    const toast = useToast();
    const store = useStore();

    const pop = reactive({
      // Popup 노출 여부
      isShowThxDollarView: false,
      isShowThxDollarList: false,
      isShowFeedback: false,
      isShowAssetsPopup: false,
      assetsPopupInfo: {
        idx: 0,
        title: "",
        contents: "",
        sdate: "",
        edate: "",
        popup_link: "",
      },
      isAssetsPopup: "N",

      hideAssetPopup: () => {
        pop.isAssetsPopup = "N";
      },
      showAssetPopup: () => {
        pop.isAssetsPopup = "Y";
      },
      hideAssetPopupOneday: () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm =
          today.getMonth() + 1 > 9
            ? today.getMonth() + 1
            : `0${today.getMonth() + 1}`;
        const dd =
          today.getDate() > 9 ? today.getDate() : `0${today.getDate()}`;
        localStorage.setItem("modalCloseTime", `${yyyy}-${mm}-${dd}`);
        localStorage.setItem("closeModal", 0);
        pop.isAssetsPopup = "N";
      },
      openUrl: (url) => {
        window.open(url);
      },

      hideFeedbackPop: () => {
        // sessionStorage.setItem("gISF", "Y");
        pop.isShowFeedback = false;
      },
      showThanksDollar: () => {
        pop.isShowThxDollarView = false;
        pop.isShowThxDollarList = false;
        if (store.state.gnb.thanks.is_new == "Y") {
          pop.isShowThxDollarView = true;
        } else {
          pop.isShowThxDollarList = true;
        }
      },
      hideThanksDollar: () => {
        pop.isShowThxDollarView = false;
      },
      hideThxDollarList: () => {
        pop.isShowThxDollarList = false;
      },
      getFeedBack: () => {
        axios.get("/rest/personal/getGNBInfo", { params: {} }).then((res) => {
          if (res.data.err == 0) {
            if (history.state.current.indexOf("login") < 0) {
              if (res.data.is_show_feedback == "Y") {
                let ss = sessionStorage.getItem("gISF");
                if (ss !== "Y") {
                  // 피드백 작성 독려 팝업(리드(센터장) 직급 이상의 리더)
                  pop.isShowFeedback = true;
                }
              }
            }
          }
        });
      },
      checkAssetsReport: () => {
        axios
          .get("/rest/personal/checkAssetsReport", { params: {} })
          .then((res) => {
            if (res.data.err == 0) {
              if (res.data.is_YN == "Y") {
                let assetTime = "";
                try {
                  assetTime = localStorage.getItem("modalCloseTime");
                } catch (err) {
                  pop.showAssetPopup();
                  return;
                }
                let t = new Date();
                let ymd =
                  t.getFullYear() +
                  "-" +
                  (t.getMonth() < 9
                    ? "0" + (t.getMonth() + 1).toString()
                    : t.getMonth() + 1) +
                  "-" +
                  (t.getDate() < 10
                    ? "0" + t.getDate().toString()
                    : t.getDate());

                if (assetTime !== ymd) {
                  pop.showAssetPopup();
                }
                pop.assetsPopupInfo = res.data.info;
              } else {
                pop.hideAssetPopup();
              }
              console.log(res.data);
            }
          });
      },
    });

    const mnu = reactive({
      moveDoc: (tp, st) => {
        router.push({
          name: "documents-AllDocumentList",
          query: {
            doc_type: tp,
            doc_state: st,
          },
        });
      },
      moveLink: (name) => {
        router.push({
          name: name,
        });
      },
    });

    const docs = reactive({
      list: {},
      total: 0,
      count: 0,

      showDetail: (id) => {
        router.push({
          name: "documents-Document-id",
          params: {
            id: id,
          },
        });
      },

      doSearch: () => {
        let params = {};
        axios
          .get("/rest/main/getDocumentList", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              docs.list = res.data.list;
              docs.total = res.data.total;
              docs.count = res.data.count;
            } else {
              if (res.data.err_msg) toast.error(res.data.err_msg);
            }
          });
      },
      chkNewBtn: (date) => {
        var nowDate = new Date();
        var tmpDate = new Date(date);
        if (nowDate - tmpDate < 86400000) return true;
        else return false;
      },
    });
    const task = reactive({
      list: {},
      total: 0,
      count: 0,

      showDetail: (idx) => {
        router.push({
          name: "TaskManagement-TaskView-idx",
          params: {
            idx: idx,
          },
        });
      },

      doSearch: () => {
        let params = {
          page: 1,
          rows: 5,
        };
        axios.get("/rest/task/getList", { params: params }).then((res) => {
          if (res.data.err == 0) {
            task.list = res.data.list;
            task.total = res.data.total;
            task.count = res.data.count;
          } else {
            if (res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },
      chkNewBtn: (date) => {
        var nowDate = new Date();
        var tmpDate = new Date(date);
        if (nowDate - tmpDate < 86400000) return true;
        else return false;
      },
    });
    const notice = reactive({
      list: {},
      total: 0,
      count: 0,

      showDetail: (id) => {
        router.push({
          name: "CARROTZone-NoticeView-idx",
          params: {
            idx: id,
          },
        });
      },

      doSearch: () => {
        let params = {
          page: 1,
          rows: 5,
        };
        axios
          .get("/rest/carrotzone/noticeList", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              notice.list = res.data.list;
              notice.total = res.data.total;
              notice.count = res.data.count;
            } else {
              if (res.data.err_msg) toast.error(res.data.err_msg);
            }
          });
      },
    });
    const statistics = reactive({
      itm: {
        company_cnt: 0,
        customer_cnt: 0,
        tutor_cnt: 0,
        class_cnt: 0,
      },
      ctm: {
        company_cnt: 0,
        customer_cnt: 0,
        tutor_cnt: 0,
        learning_satisfaction: 0,
      },
      total: 0,
      count: 0,
      doSearch: () => {
        let params = {
          page: 1,
          rows: 3,
        };
        axios
          .get("/rest/external/getStatistics", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              if (res.data.data.itm != false)
                statistics.itm = res.data.data.itm;
              if (res.data.data.ctm != false)
                statistics.ctm = res.data.data.ctm;

              console.log(statistics.ctm);
            } else {
              if (res.data.err_msg) toast.error(res.data.err_msg);
            }
          });
      },
    });
    const sales = reactive({
      list: {
        0: "",
        1: "",
        2: "",
        3: "",
        4: "",
      },
      total: 0,
      count: 0,

      showDetail: () => {
        router.push({
          name: "statusCarrotTotalSalesList",
        });
      },

      doSearch: () => {
        let params = {
          sdate: "",
          edate: "",
          company: "",
          syear: new Date().getFullYear(),
          limit: 5,
        };
        //
        axios
          .get("/rest/customermgr/getTotalSalesList", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              if (res.data.list.length > 0) sales.list = res.data.list;
              sales.total = res.data.total;
              sales.count = res.data.count;
            } else {
              if (res.data.err_msg) toast.error(res.data.err_msg);
            }
          });
      },
    });
    const mediazoneTopList = reactive({
      list: { 0: "", 1: "", 2: "" },
      total: 0,
      count: 0,
      top1: {
        thumb: "",
        kname: "",
        title: "",
      },
      top2: {
        thumb: "",
        kname: "",
        title: "",
      },
      top3: {
        thumb: "",
        kname: "",
        title: "",
      },

      showDetail: (id) => {
        router.push({
          name: "documents-Document-id",
          params: {
            id: id,
          },
        });
      },

      doSearch: () => {
        let params = {};
        axios
          .get("/rest/carrotzone/mediazoneListTopList", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              mediazoneTopList.list = res.data.list;
              mediazoneTopList.total = res.data.total;
              if (mediazoneTopList.total == 3) {
                mediazoneTopList.list[mediazoneTopList.total] =
                  res.data.list[0];
                mediazoneTopList.list[mediazoneTopList.total + 1] =
                  res.data.list[1];
                mediazoneTopList.list[mediazoneTopList.total + 2] =
                  res.data.list[2];
              }
              if (mediazoneTopList.total == 2) {
                mediazoneTopList.list[mediazoneTopList.total] =
                  res.data.list[0];
                mediazoneTopList.list[mediazoneTopList.total + 1] =
                  res.data.list[1];
              }
              if (mediazoneTopList.total == 1) {
                mediazoneTopList.list[mediazoneTopList.total] =
                  res.data.list[0];
                mediazoneTopList.list[mediazoneTopList.total + 1] =
                  res.data.list[0];
                mediazoneTopList.list[mediazoneTopList.total + 2] =
                  res.data.list[0];
              }
            } else {
              if (res.data.err_msg) toast.error(res.data.err_msg);
            }
          });
      },
    });
    const smp = reactive({
      list: {},
      total: 0,
      count: 0,

      showDetail: (idx) => {
        router.push({
          name: "customerManagement-statusSMPView-idx",
          params: {
            idx: idx,
          },
        });
      },

      doSearch: () => {
        let params = {};
        axios.get("/rest/main/getSMPList", { params: params }).then((res) => {
          if (res.data.err == 0) {
            smp.list = res.data.list;
            smp.total = res.data.total;
            smp.count = res.data.count;
          } else {
            if (res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },
      chkNewBtn: (date) => {
        var nowDate = new Date();
        var tmpDate = new Date(date);
        if (nowDate - tmpDate < 259200000) return true;
        else return false;
      },
    });
    const main = reactive({
      isLoaded: false,
      isTab: 1,
      randNum: 0,
      auto: null,
      info: {
        cnt_schedule: 0,
        cnt_bizcard: 0,
        ceomessage: {},
      },
      list: [],
      total: 0,
      dates: [],
      vlist: [],
      elist: [],
      isDateMouseOver: 999,
      mediazone: { err: 200 },
      today: 0,
      link_today: "",
      chkNewBtn7days: (date) => {
        var nowDate = new Date();
        var tmpDate = new Date(date);
        if (nowDate - tmpDate < 604800000) return true;
        else return false;
      },
      number_format: (num) => {
        return Number(num)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },

      setTab: (tab) => {
        main.isTab = tab;
        let u = "";
        let p = {};
        let d = new Date();
        let td = [
          d.getFullYear(),
          d.getMonth() > 8 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1),
          d.getDate() > 9 ? d.getDate() : "0" + d.getDate(),
        ].join("-");
        switch (tab) {
          case 1:
            u = "getCustomerDBDetailList";
            p = {
              page: 1,
              rows: 5,
            };
            break;

          case 2:
            u = "getScheduleDates";
            p = {
              sdate: td,
              edate: td,
            };
            break;

          case 3:
            u = "getBizcardList";
            p = {
              page: 1,
              rows: 5,
              state: "Y",
            };
            break;

          case 4:
            u = "getTotalSalesList";
            p = {
              sdate: "",
              edate: "",
              company: "",
              syear: new Date().getFullYear(),
              limit: 15,
            };
            break;
        }

        main.doSearch(u, p);
      },
      setAuto: () => {
        let prev = 2;
        let curr = 3;
        let next = 1;

        main.auto = setInterval(() => {
          //let tmp = 1;
          if (bbs.isTabMouseOver == 0) {
            let tmp = prev;
            prev = curr;
            curr = next;
            next = tmp;
            main.setTab(curr);
          }
        }, 10000);
      },

      doSearch: (u, p) => {
        main.list = [];
        main.total = 0;
        axios.get("/rest/customermgr/" + u, { params: p }).then((res) => {
          if (res.data.err == 0) {
            if (main.isTab == 2) {
              let l = res.data.list[0].times;
              let cnt = l.length > 3 ? 3 : l.length;
              for (let i = 0; i < cnt; i++) {
                main.list.push(l[i]);
              }
              main.total = main.list.length;
            } else {
              main.list = res.data.list;
              main.total = res.data.total;
            }
          } else {
            console.log(res.data);
          }
        });
      },

      initSearch: () => {
        axios.get("/rest/main/getDefaultInfo", { params: {} }).then((res) => {
          if (res.data.err == 0) {
            main.info = res.data;

            main.makeCalendarDates();
            main.mediazone = main.info.mediazone;
            main.isLoaded = true;
          } else {
            console.log(res.data);
          }
        });
      },

      makeCalendarDates: () => {
        let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        let sdate = new Date(main.info.sdate).getTime();
        let edate = new Date(main.info.edate).getTime();
        let holiday = main.info.vacation.holiday; // 공휴일 체크

        const today = new Date();
        const saveDay = today.getDate();
        main.today = saveDay;

        for (let i = sdate; i <= edate; i += 86400000) {
          let td = new Date(i);
          let day = days[td.getDay()];
          let date = td.getDate();
          let cdate = [
            td.getFullYear(),
            td.getMonth() < 9 ? "0" + (td.getMonth() + 1) : td.getMonth() + 1,
            td.getDate() < 10 ? "0" + td.getDate() : td.getDate(),
          ].join("-");
          let holiday_chk = "N";
          let title = "";

          for (let k = 0; k < holiday.length; k++)
            if (cdate == holiday[k].hdate) {
              holiday_chk = "Y";
              title = holiday[k].title;
            }
          main.dates.push({
            day: day,
            date: date,
            cdate: cdate,
            count: 0,
            isMore: false,
            holiday: holiday_chk,
            title: title,
          });
        }

        let list = main.info.education.list;
        for (let i = 0; i < list.length; i++) {
          let irow = list[i];

          for (let j = 0; j < main.dates.length; j++) {
            let jrow = main.dates[j];

            if (irow.sdate <= jrow.cdate && jrow.cdate <= irow.edate) {
              let nitm = { idx: irow.idx };
              nitm.cdate = jrow.cdate;
              nitm.text = irow.title;

              main.elist.push(nitm);
              jrow.count++;
            }
          }
        }

        list = main.info.vacation.list;
        for (let i = 0; i < list.length; i++) {
          let irow = list[i];
          //irow.days= irow.days+irow.holiday_cnt;//지정공휴일표시

          // 주말, 지정 공휴일 만큼 loop cnt 추가
          for (let k = 0; k < irow.days; k++) {
            //주말 미표시
            let cd = new Date(irow.sdate);
            cd.setDate(cd.getDate() + k);
            if (cd.getDay() == 6 || cd.getDay() == 0) irow.days++;
          }
          irow.days = irow.days + irow.holiday_cnt; //지정공휴일표시

          if (irow.days > 1) {
            for (let j = 0; j < irow.days; j++) {
              let cd = new Date(irow.sdate);
              let holiday_chk = false;
              cd.setDate(cd.getDate() + j);

              // 주말 미표시
              if (cd.getDay() == 6 || cd.getDay() == 0) {
                holiday_chk = true;
              }

              let nitm = { idx: irow.idx };
              nitm.cdate = [
                cd.getFullYear(),
                (cd.getMonth() + 1 > 9 ? "" : "0") + (cd.getMonth() + 1),
                (cd.getDate() > 9 ? "" : "0") + cd.getDate(),
              ].join("-");
              nitm.text = irow.ename + "(" + irow.kname + ")";
              nitm.type = irow.utype_txt;

              for (let k = 0; k < main.dates.length; k++) {
                if (nitm.cdate == main.dates[k].cdate) {
                  main.dates[k].count++;
                  break;
                }
              }

              //지정 공휴일 미표시
              for (let k = 0; k < holiday.length; k++)
                if (nitm.cdate == holiday[k].hdate) {
                  holiday_chk = true;
                }

              if (!holiday_chk) main.vlist.push(nitm);
            }
          } else {
            let nitm = { idx: irow.idx };
            nitm.cdate = irow.sdate;
            nitm.text = irow.ename + "(" + irow.kname + ")";
            nitm.type = irow.utype_txt.replace("반차", "");

            for (let k = 0; k < main.dates.length; k++) {
              if (nitm.cdate == main.dates[k].cdate) {
                main.dates[k].count++;
                break;
              }
            }

            main.vlist.push(nitm);
          }
        }
      },

      showDetail: (o) => {
        let loc = "customerDBViewTab1";
        switch (main.isTab) {
          case 1:
            loc = "customerManagement-customerDBViewTab1-idx";
            break;
          case 2:
            loc = "customerManagement-scheduleVisitView-idx";
            break;
          case 3:
            loc = "customerManagement-businessCardView-idx";
            break;
          case 4:
            sessionStorage.setItem(
              "statusCarrotTotalSalesList",
              JSON.stringify({
                company: o.idx_company,
              })
            );
            loc = "customerManagement-statusCarrotTotalSalesList";
            break;
        }

        router.push({
          name: loc,
          params: {
            idx: o.idx,
          },
        });
      },

      moveLink: (name) => {
        router.push({
          name: name,
        });
      },
      moveExternalLink: (link) => {
        window.open(link);
      },
      goDelivery: () => {
        window.open(
          "https://delivery.carrotians.net/rest/info/authLogin?uid=" +
            store.state.email,
          "delivery"
        );
      },
      goImageEditor: () => {
        if (
          window.location.host.includes("stage") ||
          window.location.host.includes("local")
        ) {
          window.open(
            `https://stage-reuters.carrotenglish.net/login?uid=${store.state.email}&type=editor`
          );
        } else {
          window.open(
            `https://reuters.carrotenglish.com/login?uid=${store.state.email}&type=editor`
          );
        }
      },
      base_convert(number, frombase, tobase) {
        return parseInt(number + "", frombase | 0).toString(tobase | 0);
      },

      /**
       * @description : 스테이지 서버
       */
      goStage: () => {
        window.open("/stage");
      },
      gofamilysite_admin: () => {
        window.open("/familysite_admin");
      },
      goInnerPlan: () => {
        window.open(
          `https://kr.object.ncloudstorage.com/intranet/3.carrot_inner_plan.pdf`
        );
      },
    });

    const roll2 = reactive({
      isLoaded: false,
      list: [],

      rollId: null,
      interval: 4000,
      prev: 0,
      curr: 0,
      next: 0,

      is_reverse: false,

      up: () => {
        roll2.is_reverse = true;

        if (roll2.rollId) {
          clearInterval(roll2.rollId);
        }

        let tmp = roll2.next;
        roll2.next = roll2.curr;
        roll2.curr = roll2.prev;
        roll2.prev = tmp;

        roll2.rollId = setInterval(() => {
          let tmp = roll2.prev;
          roll2.prev = roll2.curr;
          roll2.curr = roll2.next;
          roll2.next = tmp;
        }, roll2.interval);
      },
      moveLink: (idx, bbs_name) => {
        if (bbs_name == "mediazone") {
          router.push({
            name: "CARROTZone-MediaZoneView-idx",
            params: {
              idx: idx,
            },
          });
        }
        if (bbs_name == "ceomessage") {
          router.push({
            name: "CARROTZone-CEOMessageView-idx",
            params: {
              idx: idx,
            },
          });
        }
      },

      doSearch: () => {
        let params = {
          cnt: 20,
        };
        axios
          .get("/rest/carrotzone/mediaComments", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              roll2.list = res.data.list2;
              roll2.rollInit();
            } else {
              console.log(res.data);
            }
          });
      },

      //롤링 배너 초기화
      rollInit: (newinterval) => {
        if (parseInt(newinterval) > 0) {
          roll2.interval = newinterval;
        }
        if (roll2.rollId) {
          clearInterval(roll2.rollId);
        }

        if (roll2.list.length <= 1) {
          roll2.prev = 0;
          roll2.curr = 0;
          roll2.next = 0;
        } else if (roll2.list.length == 2) {
          roll2.prev = 0;
          roll2.curr = 1;
          roll2.next = 1;
        } else if (roll2.list.length >= 3) {
          roll2.prev = 0;
          roll2.curr = 1;
          roll2.next = 2;
        } else {
          roll2.prev = 0;
          roll2.curr = 1;
          roll2.next = 2;
        }

        roll2.isLoaded = true;

        roll2.rollId = setInterval(() => {
          if (roll2.next == roll2.list.length - 2) {
            roll2.prev = 0;
            roll2.curr = 1;
            roll2.next = 2;
          }

          if (roll2.next == roll2.list.length) {
            roll2.prev = 0;
            roll2.curr = 1;
            roll2.next = 2;
            /*
              roll2.prev = roll2.curr;
              roll2.curr = roll2.next;
              roll2.next = 0;
              */
          }
          roll2.prev = roll2.curr;
          roll2.curr = roll2.next;
          roll2.next = roll2.next + 1;

          // if(roll2.list.length-1 =)

          /*
            if (roll2.list.length >= 3 ){
              if(roll2.next == roll2.list[roll2.list.length-1]["idx"]) {
                roll2.prev = roll2.list[1]["idx"];
                roll2.curr = roll2.list[2]["idx"];
                roll2.next = roll2.list[3]["idx"];
                console.log(roll2.prev);
              }
            }else{
             let tmp = roll2.prev;
              roll2.prev = roll2.curr;
              roll2.curr = roll2.next;
              roll2.next = tmp;
            }*/
        }, roll2.interval);
      },
    });
    const bbs = reactive({
      isMounted: false,
      isLoaded: false,

      isShow: 0,
      isTabMouseOver: 0,

      page: 1,
      rows: 50,

      today: "",
      tdate: "",
      attrs: [{ key: "selected", highlight: true, dates: [new Date()] }],

      weeks: [],

      sdate: "", //
      edate: "", //
      state: "", // schedule type
      office: "0", // hq office
      team: "0", // hq team

      list: [],
      total: 0,

      dateFormat: (d) => {
        return [
          d.getFullYear(),
          d.getMonth() > 8 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1),
          d.getDate() > 9 ? d.getDate() : "0" + d.getDate(),
        ].join("-");
      },

      addSchedule: () => {
        sessionStorage.setItem(
          "scheduleVisitWeekly",
          JSON.stringify({
            sdate: bbs.sdate,
            edate: bbs.edate,
            state: bbs.state,
            office: bbs.office,
            team: bbs.team,
          })
        );

        router.push({
          name: "customerManagement-scheduleVisitAdd",
        });
      },

      showSchedule: (idx) => {
        sessionStorage.setItem(
          "scheduleVisitWeekly",
          JSON.stringify({
            sdate: bbs.sdate,
            edate: bbs.edate,
            state: bbs.state,
            office: bbs.office,
            team: bbs.team,
          })
        );

        router.push({
          name: "customerManagement-scheduleVisitView-idx",
          params: {
            idx: idx,
          },
        });
      },

      doSelect: () => {
        let nd = new Date(bbs.tdate);
        let td = nd.getDate() - nd.getDay();
        let fd = new Date(nd.setDate(td));
        let ld = new Date(fd.getFullYear(), fd.getMonth(), fd.getDate() + 6);
        bbs.sdate = [
          fd.getFullYear(),
          fd.getMonth() > 8 ? fd.getMonth() + 1 : "0" + (fd.getMonth() + 1),
          fd.getDate() > 9 ? fd.getDate() : "0" + fd.getDate(),
        ].join("-");
        bbs.edate = [
          ld.getFullYear(),
          ld.getMonth() > 8 ? ld.getMonth() + 1 : "0" + (ld.getMonth() + 1),
          ld.getDate() > 9 ? ld.getDate() : "0" + ld.getDate(),
        ].join("-");

        for (let i = 0; i < 7; i++) {
          bbs.attrs[0].dates[i] = new Date(
            fd.getFullYear(),
            fd.getMonth(),
            fd.getDate() + i
          );
          bbs.weeks[i] = bbs.dateFormat(bbs.attrs[0].dates[i]);
        }
        bbs.doSearch();
      },

      doPrev: () => {
        let nd = new Date(bbs.sdate);
        bbs.tdate = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate() - 7);
        bbs.doSelect();
      },

      doNext: () => {
        let nd = new Date(bbs.sdate);
        bbs.tdate = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate() + 7);
        bbs.doSelect();
      },

      doSearch: () => {
        if (bbs.isMounted == false) return;
        let params = {
          sdate: bbs.sdate,
          edate: bbs.edate,
          state: bbs.state,
          office: bbs.office,
          team: bbs.team,
        };

        axios
          .get("/rest/customermgr/getScheduleDates", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              bbs.list = res.data.list;
              bbs.total = res.data.total;

              bbs.isLoaded = true;
            } else {
              if (res.data.err_msg) toast.error(res.data.err_msg);
            }

            sessionStorage.removeItem("scheduleVisitWeekly");
          });
      },
      chkNewBtn: (date) => {
        var nowDate = new Date();
        var tmpDate = new Date(date);
        if (nowDate - tmpDate < 86400000) return true;
        else return false;
      },
    });

    const bbs_comment = reactive({
      isOwner: false,
      isAuth: false,
      isLoaded: false,

      idx: 0,
      info: {},

      showList: () => {
        router.push({ name: "CARROTZone-CarrotManualList" });
      },

      doDelete: () => {
        Swal.fire({
          title: "CARROT Manual",
          text: "삭제하시겠습니까?",
          showCancelButton: true,
        }).then((res) => {
          if (res.isConfirmed) {
            let params = {
              idx: bbs.idx,
            };
            axios
              .post("/rest/carrotzone/carrotmanualDel", params)
              .then((res) => {
                if (res.data.err == 0) {
                  router.push({
                    name: "CARROTZone-CarrotManualList",
                  });
                } else {
                  if (res.data.err_msg) toast.error(res.data.err_msg);
                }
              });
          }
        });
      },

      showMod: () => {
        router.push({
          name: "CARROTZone-CarrotManualMod-idx",
          params: { idx: bbs.idx },
        });
      },

      getInfo: () => {
        let params = {
          idx: 1,
        };
        axios
          .get("/rest/carrotzone/carrotmanualView", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              bbs.info = res.data;

              bbs.isOwner = bbs.info.is_owner == "Y" ? true : false;
              bbs.isAuth = bbs.info.is_auth == "Y" ? true : false;
              bbs.isLoaded = true;
            } else {
              if (res.data.err_msg) toast.error(res.data.err_msg);
            }
          });
      },

      setGood: (idx) => {
        let params = {
          idx: idx,
        };
        axios
          .get("/rest/carrotzone/mediazoneGood", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              for (var i = 0; i < main.mediazone.total; i++) {
                if (main.mediazone.list[i].idx == idx) {
                  main.mediazone.list[i].good = res.data.cnt;
                  main.mediazone.list[i].is_good = res.data.is_good;
                }
              }
            } else {
              if (res.data.err_msg) toast.error(res.data.err_msg);
            }
          });
      },
    });

    // judy님 추모 팝업
    const showPopup = ref(false);
    const closePopup = () => {
      showPopup.value = false;
    };
    const closeToday = () => {
      const today = new Date();
      const expires = new Date(today);
      expires.setDate(today.getDate() + 1);
      localStorage.setItem("judy-popup-closed", expires.toISOString());
      closePopup();
    };

    onMounted(() => {
      // Mounted
      console.log(store.state);
      sales.doSearch();
      docs.doSearch();
      task.doSearch();
      smp.doSearch();
      notice.doSearch();
      statistics.doSearch();
      mediazoneTopList.doSearch();
      main.isTab = 1;
      main.setTab(2);
      // main.setAuto();

      main.initSearch();
      roll2.doSearch();
      let nd = new Date();
      bbs.today = bbs.dateFormat(nd);
      bbs.tdate = bbs.dateFormat(nd);
      bbs.doSelect();

      let today = new Date();
      let year = today.getFullYear();
      let month = ("0" + (today.getMonth() + 1)).slice(-2);
      let day = ("0" + today.getDate()).slice(-2);
      main.link_today = year + month + day;

      let ss = sessionStorage.getItem("scheduleVisitWeekly");
      if (ss) {
        ss = JSON.parse(ss);
        bbs.page = ss.page ? ss.page : 1;
        if (ss.sdate) bbs.sdate = ss.sdate;
        if (ss.edate) bbs.edate = ss.edate;
        bbs.state = ss.state ? ss.state : "";
        bbs.office = ss.office > 0 ? ss.office : 0;
        bbs.team = ss.team > 0 ? ss.team : 0;
      }

      bbs.isMounted = true;
      bbs.doSearch();
      bbs_comment.getInfo();

      main.randNum = Math.floor(Math.random() * 3); // 0~2
      pop.getFeedBack();
      pop.checkAssetsReport();

      // judy님 추모 팝업 5/27까지만 보이기
      const popupClosedDate = localStorage.getItem("judy-popup-closed");
      const now = new Date();
      const displayUntil = new Date("2024-05-27T23:59:59");

      if (!popupClosedDate || new Date(popupClosedDate) < now) {
        if (now <= displayUntil) {
          showPopup.value = true;
        }
      }
    });

    onUnmounted(() => {
      // UnMounted
      window.scrollTo(0, 0);
      clearInterval(main.auto);
    });

    return {
      pop,
      mnu,
      docs,
      smp,
      main,
      store,
      notice,
      bbs,
      roll2,
      mediazoneTopList,
      sales,
      statistics,
      bbs_comment,
      task,
      showPopup,
      closePopup,
      closeToday,
    };
  },
};
</script>
<style lang="scss" scoped>
.main-con .con-media-board .con-media-board-wrap {
  .slide-list {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      position: absolute;
      width: calc(100%);
      height: 100%;
      left: 100%;
      background-color: #fff;
      .slide-img {
        text-align: center;
      }
      &.currentroll {
        left: 0;
        transition: left 0.5s ease-in-out, right 0.5s ease-in-out;
      }
      &.prevroll {
        left: -100%;
        transition: left 0.5s ease-in-out, right 0.5s ease-in-out;
      }
      &.nextroll {
        left: 100%;
        display: block;
        // transition: none;
        transition: left 0.5s ease-in-out, right 0.5s ease-in-out;
      }
    }
    &.reverse li {
      &.prevroll {
        transition: none;
      }
      &.nextroll {
        transition: left 0.5s ease-in-out, right 0.5s ease-in-out;
      }
    }
  }
}
.row {
  margin: 0 -10px;
  .col-6 {
    float: left;
    width: 50%;
    padding: 0 10px;
  }
}
.board-media {
  .title {
    .new {
      position: relative;
      width: 14px;
      height: 14px;
      font-size: 0;
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
      &::before {
        content: "N";
        display: block;
        font-size: 10px;
        line-height: 12px;
        color: red;
      }
    }
  }
}

.main-table-padding {
  padding-left: 15px;
  padding-right: 15px;
}
.stage-site {
  &:after {
    content: "";
    display: inline-block;
    border-right: 2px solid #e9e9e9;
    height: 13px;
    padding: 0 10px;
  }
}
.familyadmin-site {
  padding: 0 0 0 17px;
}

.inner-plan {
  &::before {
    content: "";
    display: inline-block;
    border-right: 2px solid #e9e9e9;
    height: 13px;
    padding: 0 10px;
  }
}

.admin-site {
  padding-left: 13px;
  padding-right: 13px;
}

// judy님 추모 팝업
.judy-popup {
  position: fixed;
  inset: 0;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  z-index: 1000;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;

    .inner {
      max-width: 500px;
      padding: 20px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.5);

      img {
        display: block;
        width: 100%;
      }

      .close {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;

        img {
          width: 15px;
          cursor: pointer;
        }
      }

      .judy-img {
        margin-bottom: 10px;
      }

      .btn-list {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .go {
          font-size: 16px;
          text-decoration: underline;
        }

        .close-today {
          color: #575757;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
